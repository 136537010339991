export enum appRoutesEnum {
  BILLING = '/billing',
  DASHBOARD = '/dashboard',
  // Account management feature
  ACCOUNT_MANAGEMENT = '/account-management',
  // Monitoring feature
  MONITORING = '/monitoring',
  ACCOUNT_RESOURCES_EXECUTIONS_MONITORING = '/executions-monitoring',
  SOURCE_CATALOG = '/source-catalog',
  SOURCE_CATALOG_EDIT = '/source-catalog/edit',
  SOURCE_CATALOG_CREATE = '/source-catalog/create',

  // Destination feature
  DESTINATION = '/destinations',
  // Sync feature
  SYNCS = '/syncs',
  SYNCS_CREATE = '/syncs/create',
  SYNCS_EDIT = '/syncs/edit',
  // New Syncs feature
  NEW_SYNCS = '/new-syncs',
  NEW_SYNCS_CREATE = '/new-syncs/create',
  NEW_SYNCS_EDIT = '/new-syncs/edit',
  // Connection feature
  CONNECTIONS = '/connections',
  CONNECTIONS_CREATE = '/connections/create',
  CONNECTIONS_EDIT = '/connections/edit',
  // Dataset feature
  DATASETS = '/datasets',
  DATASETS_PREPARE_DATASET = '/datasets/prepare-dataset',
  DATASETS_EDIT_DATASET = '/datasets/edit-data',
  // Audience feature
  AUDIENCES = '/audiences',
  AUDIENCES_ARCHITECTURE = '/audiences/architecture',
  AUDIENCES_CREATE = '/audiences/create',
  AUDIENCE_SQL_VIEW = '/audiences/sql/view',
  AUDIENCES_PREPARE_DATA = '/audiences/prepare-data',
  AUDIENCES_EDIT_DATA = '/audiences/edit-data',
  SQL_AUDIENCES = '/audiences/sql-editor',
  // DataWarehouse feature
  CREATE_DATAWAREHOUSE = '/dataWarehouse/create',
  SETTINGS = '/settings/:tab',
  SETTINGS_ACCOUNT = '/settings/account',
  PROFILE = '/settings/profile',
  PLAN = '/settings/plan',
}
export enum authRoutesEnum {
  // Auth layout
  LOGOUT = '/logged-out',
  ACCOUNT_SELECTOR = '/accounts',
}
export enum layoutRoutesEnum {
  // APP layout
  MAIN = '/',
  APP = '/account',
  NOT_FOUND = '/not-found',
}
export enum queryParamKeysEnum {
  ELEMENT = 'e',
  DATASET_NAME = 'name',
  DATASET_ENTITY = 'entity',
}

export enum pathParamKeysEnum {
  ACCOUNT_ID = 'accountId',
}
export enum hashParamKeysEnum {
  VIEW = '#view',
  SETTINGS = '#settings',
  TRANSFORM = '#transform',
  SOURCES = '#sources',
  CONFIGURATION = '#configuration',
  DATA_MODEL = '#data-model',
}
