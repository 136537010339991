import {
  createAuth0Client,
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';

let _initOptions: Auth0ClientOptions;
let _client: Auth0Client;

export const getAuth0Client = async (initOptions?: Auth0ClientOptions) => {
  if (_client) {
    return _client;
  }

  try {
    if (initOptions) {
      _initOptions = initOptions;
    }

    _client = await createAuth0Client(initOptions ?? _initOptions);
    return _client;
  } catch (e: any) {
    throw new Error(`getAuth0Client Error ${e}`);
  }
};

export const getTokenSilently = async (options?: GetTokenSilentlyOptions) => {
  if (!_client) {
    throw new Error('Client is not defined');
  }
  return await _client.getTokenSilently(options);
};

export const getLatestToken = async (options?: RedirectLoginOptions<any>) => {
  if (!_client) {
    throw new Error('Client is not defined');
  }
  try {
    const token = await _client.getTokenSilently();
    return token;
  } catch (error) {
    await _client.loginWithPopup(options);
    const token = await _client.getTokenSilently();
    return token;
  }
};
