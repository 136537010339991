import { useCallback, useEffect, useState } from 'react';
import { ApolloQueryResult, DocumentNode, OperationVariables, QueryOptions } from '@apollo/client';

import apolloClientInstance from '@apolloClientInstance';
import { getApiErrorMessage } from './api-error-handler';

type QueryResultInfo<Data, Variables> = { data: Data | null; variables?: Variables };

type OptionsWithoutFormat<Data, Variables> = {
  query: DocumentNode;
  variables?: Variables;
  refetchDependencies?: any[];
  skip?: boolean;
  pollInterval?: number;
  abortSignal?: AbortSignal;
  onDataFetched?: (result: QueryResultInfo<Data, Variables>) => void;
  onError?: (error: unknown) => void;
};

type OptionsWithFormat<Data, Variables, FormattedData = Data> = OptionsWithoutFormat<
  Data,
  Variables
> & {
  format: (result: QueryResultInfo<Data, Variables>) => FormattedData;
};

type Options<Data, Variables, FormattedData> = Data extends FormattedData
  ? OptionsWithoutFormat<Data, Variables>
  : OptionsWithFormat<Data, Variables, FormattedData>;

export const apolloQuery = async <T = any, TVariables = OperationVariables>(
  options: QueryOptions<TVariables, T>,
  abortSignal?: AbortSignal
): Promise<ApolloQueryResult<T>> => {
  const apolloClient = await apolloClientInstance({ abortSignal });
  return apolloClient.query(options as QueryOptions<OperationVariables, T>);
};

export const useQuery = <Data = any, Variables = OperationVariables, FormattedData = Data>(
  options: Options<Data, Variables, FormattedData>
): {
  data: FormattedData | null;
  variables: Variables | undefined;
  error: unknown;
  errorMessage: string | null;
  fetching: boolean;
  loaded: boolean;
  refetch: (
    refetchVariables?: Variables | undefined,
    refetchAbortSignal?: AbortSignal
  ) => Promise<void>;
  loadMore: (
    loadMoreVariables: Partial<Variables>,
    mergeData: (
      currentData: FormattedData | null,
      fethedData: FormattedData | null
    ) => FormattedData,
    loadMoreAbortSignal?: AbortSignal
  ) => void;
  setCache: (
    update:
      | QueryResultInfo<FormattedData, Variables>
      | ((
          prevState: QueryResultInfo<FormattedData, Variables>
        ) => QueryResultInfo<FormattedData, Variables>)
  ) => void;
} => {
  const {
    query,
    variables,
    refetchDependencies = [],
    skip = false,
    pollInterval,
    abortSignal,
    onDataFetched,
    onError,
  } = options;

  const format = (result: QueryResultInfo<Data, Variables>): FormattedData => {
    if ('format' in options) {
      return options.format(result);
    }

    return result.data as any as FormattedData;
  };

  const [cache, setCache] = useState<QueryResultInfo<FormattedData, Variables>>({ data: null });
  const [fetching, setFetching] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchData = async (fetchVariables?: Variables, abortSignal?: AbortSignal) => {
    const internalVariables = fetchVariables ?? variables;

    try {
      setFetching(true);

      const res = await apolloQuery<Data | null, Variables>(
        { query, variables: internalVariables },
        abortSignal
      );

      const resultInfo = { data: res.data ?? null, variables: internalVariables };
      onDataFetched?.(resultInfo);

      setErrorMessage(null);
      setError(null);
      setFetching(false);
      setLoaded(true);

      return resultInfo;
    } catch (error) {
      onError?.(error);

      const errorMessage = getApiErrorMessage(error);

      if (errorMessage) {
        setErrorMessage(errorMessage);
      }

      setError(error);
      setFetching(false);
      setLoaded(true);
    }

    return { data: null, variables: internalVariables };
  };

  const doFetch = async () => {
    const result = await fetchData(variables, abortSignal);
    setCache({ data: format(result), variables: result.variables });
  };

  const loadMore = useCallback(
    (
      loadMoreVariables: Partial<Variables>,
      mergeData: (
        currentData: FormattedData | null,
        fethedData: FormattedData | null
      ) => FormattedData,
      loadMoreAbortSignal?: AbortSignal
    ) => {
      if (pollInterval && pollInterval > 0) {
        console.warn(
          'Please note that the load more functionality in useQuery is not designed to be compatible with polling. Attempting to use both simultaneously may result in unexpected behavior.'
        );
      }

      const internalVariables = variables ? { ...variables, ...loadMoreVariables } : undefined;

      fetchData(internalVariables, loadMoreAbortSignal).then((fetchedData) => {
        setCache((currentCache) => ({
          data: mergeData(
            currentCache.data,
            format({ data: fetchedData.data, variables: internalVariables })
          ),
          variables: internalVariables,
        }));
      });
    },
    [...refetchDependencies, query, pollInterval]
  );

  const refetch = async (refetchVariables?: Variables, refetchAbortSignal?: AbortSignal) => {
    const result = await fetchData(refetchVariables, refetchAbortSignal);
    setCache({ data: format(result), variables: result.variables });
  };

  useEffect(() => {
    if (skip) {
      return;
    }

    doFetch();

    if (pollInterval && pollInterval > 0) {
      const interval = setInterval(doFetch, pollInterval);
      return () => clearInterval(interval);
    }
  }, [...refetchDependencies, pollInterval]);

  return {
    data: cache.data,
    variables: cache.variables,
    error,
    errorMessage,
    fetching,
    loaded,
    refetch,
    loadMore,
    setCache,
  };
};
