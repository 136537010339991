// Importing type
import * as types from '../types';
import { GetAudienceApiCall } from '../types';

import {
  APIDraftAudienceSource,
  APIDraftSource,
  APIDraftSyncSource,
  APIGetAudiencePreviewSample,
  APIPreviewAudience,
  APIRequestDraftSource,
  APIRequestPreviewAudience,
  APIRequestSqlAudienceQueryDescriptor,
  APISqlAudienceQueryDescriptor,
  DatabaseSchemaEntity,
  DescribeSqlAudienceSourcesShapes,
  GetSourceEntitiesOutput,
  RequestPreviewAudienceInput,
  SourceEntitiesOutputTypes,
  SqlAudienceQueryDescriptorInput,
} from '@features/audiences/ducks/api/types';
import {
  AUDIENCE_PREVIEW,
  DRAFT_AUDIENCE_SOURCE,
  DRAFT_SOURCE,
  GET_AUDIENCE,
  GET_AUDIENCE_EXECUTIONS,
  GET_AUDIENCE_PREVIEW_SAMPLE,
  GET_AUDIENCES,
  GET_DEPLOYED_AUDIENCE,
  GET_DEPLOYED_AUDIENCES,
  GET_SOURCE_ENTITIES,
  SQL_AUDIENCE_QUERY_DESCRIPTOR,
  GET_AUDIENCE_TABLE_LIST,
  LIST_BUSINESS_TERMS,
  GET_AUDIENCE_DATA_V2,
  DRAFT_SYNC_SOURCE,
} from '@features/audiences/ducks/api/graphql/queries';
import {
  toFrontAudienceData,
  toFrontDeployedAudienceType,
  toFrontType,
} from '@features/audiences/ducks/api/mappingAudienceTypes';
import {
  CREATE_AUDIENCE,
  DELETE_AUDIENCE,
  DESCRIBE_SQL_AUDIENCE_SOURCES_SHAPES,
  REQUEST_AUDIENCE_EXECUTION,
  REQUEST_AUDIENCE_PREVIEW,
  REQUEST_DRAFT_SOURCE,
  REQUEST_SQL_AUDIENCE_QUERY_DESCRIPTOR,
  UPDATE_AUDIENCE,
} from '@features/audiences/ducks/api/graphql/mutations';
import {
  CreateAudienceCommand,
  getCreateAudienceCommand,
} from './mappingAudienceTypes/actions/getCreateAudienceCommand';
import { FetchResult } from '@apollo/client';
import {
  getUpdateAudienceCommand,
  UpdateAudienceCommand,
} from './mappingAudienceTypes/actions/getUpdateAudienceCommand';
import apolloClientInstance from '@apolloClientInstance';
import { BackDeployedAudience } from './audienceBackTypes/deployedAudience';
import { DeployedAudience } from '../../../audiences/types/DeployedAudience';
import { mapBaseSourceConfigToDataSource } from '../../../audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingSourcesProperty';
import { toBackType } from '../../../audiences/ducks/api/mappingAudienceTypes/toBackType/toBackType';
import { AudienceUnion } from '../../../audiences/types/Audience/Audience';
import { AudienceReadModel } from './audienceBackTypes/audience';
import { mappingDeduplicationKeysToFront } from './mappingAudienceTypes/toFrontType/mappingDeduplicationKeysToFront';
import { mappingSqlAudienceSpecificationProperty } from '../../../audiences/ducks/api/mappingAudienceTypes/toBackType/mappingAudienceSpecificationProperty/mappingAudienceSpecificationProperty';
import { AudienceDeduplicationKey } from '../../../audiences/types';
import { DataSourceType } from '../../../audiences/ducks/api/audienceBackTypes/source';
import { mapBackendAudienceSpecificationToDataSources } from '../../../audiences/ducks/api/mappingAudienceTypes/helpers/mapAudienceSpecificationToSources';
import {
  APIListBusinessTerms,
  ListBusinessTermsOutput,
} from '../../../audiences/ducks/api/types/ListBusinessTerms';
import {
  AudienceExecutionResultsTypes,
  RequestAudienceExecutionResult,
} from '../../types/AudienceExecution/RequestAudienceExecution';

const getAudiences: types.APIGetAudiences = async (accountId, abortSignal?: AbortSignal) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const apolloClient = await apolloClientInstance({ abortSignal });

  const res = await apolloClient.query({
    query: GET_AUDIENCES,
    variables: {
      accountId,
    },
  });

  const audiences = res.data.audiences
    .filter(({ specification }: AudienceReadModel) => {
      const sources = mapBackendAudienceSpecificationToDataSources(specification);
      return sources.every((source) => Object.values(DataSourceType).includes(source.type));
    })
    .map(toFrontType);

  if (!audiences) {
    error = true;
    errorDetails = 'No Datasets found.';
  }
  return {
    audiences,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getAudiencesTableList = async (
  accountId: string,
  abortSignal?: AbortSignal
): Promise<types.ApiGetAudienceTableListPayload> => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const apolloClient = await apolloClientInstance({ abortSignal });

  const res = await apolloClient.query({
    query: GET_AUDIENCE_TABLE_LIST,
    variables: {
      accountId,
    },
  });

  const audiences = res.data.audiences
    .filter(({ specification }: AudienceReadModel) => {
      const sources = mapBackendAudienceSpecificationToDataSources(specification);
      return sources.every((source) => Object.values(DataSourceType).includes(source.type));
    })
    .map(toFrontType);
  const deployedAudiences: { id: string; name: string; numberOfRecords: number }[] =
    res.data.deployedAudiences;
  if (!audiences || !deployedAudiences) {
    error = true;
    errorDetails = 'No Datasets found.';
  }
  return {
    audiences,
    deployedAudiences,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getAudience: types.APIGetAudience = async (audienceKey, abortSignal?: AbortSignal) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let audience: AudienceUnion | undefined = undefined;
  const apolloClient = await apolloClientInstance({ abortSignal });
  // TODO @@@@koralex it should be AudienceUnion?
  const res = await apolloClient.query<{ audience: AudienceReadModel | null }>({
    query: GET_AUDIENCE,
    variables: {
      id: audienceKey,
    },
  });
  if (res.data.audience) {
    audience = {
      ...toFrontType(res.data.audience),
      status: false,
      deployment: res.data.audience.deployment,
      // TODO @@@@koralex [REFACTORING 1] should not be here
      // TODO @@@@koralex [REFACTORING 1] align ActiveAudience and Audience interfaces used there
      // type: sourceTypesEnum.AUDIENCE,
    } as AudienceUnion;
  } else {
    error = true;
    errorDetails = 'No Dataset found.';
  }
  return {
    error,
    audience,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const saveAudience: types.APISaveAudience = async (activeAudience, accountId, dataWarehouseId) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const audienceCommandSpec: CreateAudienceCommand = getCreateAudienceCommand(
    activeAudience,
    accountId,
    dataWarehouseId
  );
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: CREATE_AUDIENCE,
    variables: {
      command: audienceCommandSpec,
    },
  });

  const audience: AudienceUnion = {
    ...toFrontType(res.data.createAudience),
    status: false,
    deployment: res.data.createAudience.deployment,
    // type: sourceTypesEnum.AUDIENCE,
  } as AudienceUnion;
  if (!audience) {
    error = true;
    errorDetails = 'Failed to create Dataset.';
  }

  return {
    error,
    audience,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const updateAudience: types.APISaveAudience = async (
  activeAudience,
  accountId,
  dataWarehouseId
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const audienceCommandSpec: UpdateAudienceCommand = getUpdateAudienceCommand(
    activeAudience,
    accountId,
    dataWarehouseId
  );
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: UPDATE_AUDIENCE,
    variables: {
      command: audienceCommandSpec,
    },
  });
  //  console.log(res);
  const audience: AudienceUnion = {
    ...toFrontType(res.data.updateAudience),
    status: false,
    deployment: res.data.updateAudience.deployment,
    // type: sourceTypesEnum.AUDIENCE,
  } as AudienceUnion;

  // console.log(audience);
  if (!audience) {
    error = true;
    errorDetails = 'Failed to create Dataset.';
  }

  return {
    error,
    audience,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const deleteAudience: types.APIDeleteAudience = async (audience) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate({
    mutation: DELETE_AUDIENCE,
    variables: {
      command: {
        id: audience.key,
      },
    },
  });
  if (res.data.deleteAudience._typename === 'AudienceNotFound') {
    error = true;
    errorDetails = 'Impossible to delete Dataset (not found).';
  }

  return {
    error,
    audienceId: res.data.deleteAudience.id,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getDeployedAudiences: types.APIGetDeployedAudiences = async (accountId, abortSignal) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const apolloClient = await apolloClientInstance({ abortSignal });

  const res = await apolloClient.query<any>({
    query: GET_DEPLOYED_AUDIENCES,
    variables: {
      accountId,
    },
  });

  const deployedAudiences = (res.data.deployedAudiences || []).map((elem: BackDeployedAudience) => {
    const deployedAudience: DeployedAudience = toFrontDeployedAudienceType(elem);
    return deployedAudience;
  });

  if (!deployedAudiences.length) {
    error = true;
    errorDetails = 'No deployed Datasets found.';
  }
  return {
    deployedAudiences,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getDeployedAudience: types.APIGetDeployedAudience = async (audienceId, abortSignal) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let deployedAudience: DeployedAudience | null;
  const apolloClient = await apolloClientInstance({ abortSignal });

  const res = await apolloClient.query<GetAudienceApiCall>({
    query: GET_DEPLOYED_AUDIENCE,
    variables: {
      id: audienceId,
    },
  });
  const backDeployedAudience = res.data.deployedAudience;

  if (!backDeployedAudience) {
    error = true;
    errorDetails = 'No deployed Datasets found.';
    deployedAudience = backDeployedAudience;
  } else {
    deployedAudience = toFrontDeployedAudienceType(backDeployedAudience);
  }
  return {
    deployedAudience,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getAudienceData: types.APIGetAudienceData = async (
  audienceId,
  paging,
  search,
  abortSignal
) => {
  const apolloClient = await apolloClientInstance({ abortSignal });

  const res = await apolloClient.query({
    query: GET_AUDIENCE_DATA_V2,
    variables: {
      query: {
        audienceId,
        search,
        skip: (paging.page - 1) * paging.pageSize,
        take: paging.pageSize,
      },
    },
  });
  if (res.error || res.errors) {
    throw res.error || res.errors;
  }
  if (res.data.__typename === 'AudienceDataAudienceNotFoundOrNotDeployed') {
    throw new Error('No Dataset found.');
  }
  if (!res.data.audienceDataV2) {
    throw new Error('Unexpected error');
  }
  const audienceData = toFrontAudienceData(res.data.audienceDataV2, paging, search);
  return audienceData;
};
const requestAudienceExecution: types.APIRequestAudienceExecution = async (command) => {
  const apolloClient = await apolloClientInstance();
  const res = await apolloClient.mutate<{
    requestAudienceExecution: RequestAudienceExecutionResult;
  }>({
    mutation: REQUEST_AUDIENCE_EXECUTION,
    variables: {
      command,
    },
  });
  if (res.errors) {
    throw res.errors;
  }
  if (!res.data) {
    throw new Error('Unexpected error');
  }
  const resultType = res.data.requestAudienceExecution.__typename;
  switch (resultType) {
    case AudienceExecutionResultsTypes.audienceExecutionRequestAccepted:
      return {
        executionId: res.data.requestAudienceExecution.executionId,
      };
    case AudienceExecutionResultsTypes.audienceExecutionAudienceNotDeployed:
      throw new Error('Dataset not deployed');
    case AudienceExecutionResultsTypes.audienceExecutionAudienceNotFound:
      throw new Error('Dataset not found');
    case AudienceExecutionResultsTypes.audienceExecutionInvalidRequest:
      throw new Error('Invalid request');
    case AudienceExecutionResultsTypes.audienceRecordsScopeIsEmpty:
      throw new Error('Records scope is empty');
    default:
      throw new Error(`Case ${resultType} is not supported`);
  }
};

const describeSqlAudienceSourcesShapes: DescribeSqlAudienceSourcesShapes = async (
  sources,
  accountId,
  dataWarehouseId,
  abortSignal
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const sourcesBackType = sources.map(mapBaseSourceConfigToDataSource);

  const command = {
    accountId,
    dataWarehouseId,
    sources: sourcesBackType.map((source) => ({ source })),
  };
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.mutate({
    mutation: DESCRIBE_SQL_AUDIENCE_SOURCES_SHAPES,
    variables: {
      command: command,
    },
  });
  const sourcesResponse = res.data.describeSqlAudienceSourcesShapes;

  if (!sourcesResponse) {
    error = true;
    errorDetails = 'error with describe SQL Dataset sources shapes ';
  }

  return {
    sources: sourcesResponse.sources,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const requestSqlAudienceQueryDescriptor: APIRequestSqlAudienceQueryDescriptor = async (
  audience,
  accountId,
  dataWarehouseId,
  abortSignal
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const specification = mappingSqlAudienceSpecificationProperty(audience);
  const command: SqlAudienceQueryDescriptorInput = {
    accountId,
    dataWarehouseId,
    sqlQuery: specification.sqlQuery,
    sqlMode: specification.sqlMode,
    sources: specification.sources,
  };
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.mutate({
    mutation: REQUEST_SQL_AUDIENCE_QUERY_DESCRIPTOR,
    variables: {
      command: command,
    },
  });
  const requestSqlAudienceQueryDescriptorId = res.data.requestSqlAudienceQueryDescriptor.id;
  if (!requestSqlAudienceQueryDescriptorId) {
    error = true;
    errorDetails = 'error with SQL Dataset query descriptor';
  }
  return {
    id: requestSqlAudienceQueryDescriptorId,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const sqlAudienceQueryDescriptor: APISqlAudienceQueryDescriptor = async (
  requestSqlAudienceQueryDescriptorId,
  abortSignal
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const apolloClient = await apolloClientInstance({ abortSignal });

  const res = await apolloClient.query({
    query: SQL_AUDIENCE_QUERY_DESCRIPTOR,
    variables: {
      id: requestSqlAudienceQueryDescriptorId,
    },
  });

  const sqlAudienceQueryDescriptor = res.data.sqlAudienceQueryDescriptor;
  if (!res.data.sqlAudienceQueryDescriptor) {
    error = true;
    errorDetails = 'error with SQL Dataset query descriptor';
  }
  return {
    ...sqlAudienceQueryDescriptor,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};

const requestPreviewAudience: APIRequestPreviewAudience = async (
  audience,
  accountId,
  dataWarehouseId,
  traceparent,
  abortSignal
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  const audienceBackType = toBackType({
    audience,
    accountId,
    dataWarehouseId,
  });

  const requestAudiencePreviewCommand: RequestPreviewAudienceInput = {
    accountId,
    dataWarehouseId,
    name: audienceBackType.name,
    longName: audienceBackType.longName,
    businessCategory: audienceBackType.businessCategory,
    specification: audienceBackType.specification,
  };
  const apolloClient = await apolloClientInstance({ abortSignal, traceparent });
  const res = await apolloClient.mutate({
    mutation: REQUEST_AUDIENCE_PREVIEW,
    variables: {
      command: requestAudiencePreviewCommand,
    },
  });
  const audiencePreviewId = res.data.requestAudiencePreview.audiencePreviewId;
  if (!audiencePreviewId) {
    error = true;
    errorDetails = 'error with preview Dataset ';
  }
  return {
    audiencePreviewId,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const previewAudience: APIPreviewAudience = async (
  audiencePreviewId,
  { page, pageSize },
  abortSignal
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;

  console.assert(page > 0, `Dataset preview sample page number must be greater than zero`);
  console.assert(pageSize > 0, `Dataset preview sample page size must be greater than zero`);

  const apolloClient = await apolloClientInstance({ abortSignal });

  const res = await apolloClient.query({
    query: AUDIENCE_PREVIEW,
    variables: {
      id: audiencePreviewId,
      skip: (page - 1) * pageSize,
      take: pageSize,
    },
  });

  const audiencePreview = res.data.audiencePreview;
  if (!res.data.audiencePreviewId) {
    error = true;
    errorDetails = 'error with preview Dataset ';
  }
  return {
    ...audiencePreview,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const getAudiencePreviewSample: APIGetAudiencePreviewSample = async (
  audiencePreviewId,
  { page, pageSize }
) => {
  console.assert(page > 0, `Dataset preview sample page number must be greater than zero`);
  console.assert(pageSize > 0, `Dataset preview sample page size must be greater than zero`);

  const apolloClient = await apolloClientInstance();

  const res = await apolloClient.query({
    query: GET_AUDIENCE_PREVIEW_SAMPLE,
    variables: {
      id: audiencePreviewId,
      skip: (page - 1) * pageSize,
      take: pageSize,
    },
  });

  if (res.error || res.errors) {
    throw res.error || res.errors;
  }

  return res.data.audiencePreview.sample;
};

const getAudienceExecutions: types.APIGetAudienceExecutions = async (query) => {
  const apolloClient = await apolloClientInstance();
  const res: FetchResult<any, Record<string, any>, Record<string, any>> = await apolloClient.mutate(
    {
      mutation: GET_AUDIENCE_EXECUTIONS,
      variables: {
        audienceId: query.audienceId,
        query: {},
      },
    }
  );
  if (res.data.audience.executions) {
    return {
      audienceExecutions: res.data.audience.executions,
      loaded: true,
    };
  }
  return {
    error: true,
    loaded: true,
    errorDetails: 'Get Dataset execution failed',
    audienceExecutions: [],
  };
};

const getSourceEntities: types.APIGetSourceEntities = async (accountId, command) => {
  let sourceEntities: GetSourceEntitiesOutput;
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  const apolloClient = await apolloClientInstance();
  const res: any = await apolloClient.query({
    query: GET_SOURCE_ENTITIES,
    variables: {
      accountId,
      settings: command,
    },
  });
  const { type, entities } = res.data.getSourceEntities;

  sourceEntities = {
    type,
    entities,
  };

  if (sourceEntities.type === SourceEntitiesOutputTypes.db) {
    sourceEntities = {
      ...sourceEntities,
      entities: (sourceEntities.entities as DatabaseSchemaEntity[]).map((sourceEntity) => {
        return {
          ...sourceEntity,
          views: sourceEntity.views.map((view) => ({
            ...view,
            schema: sourceEntity.key,
          })),
          tables: sourceEntity.tables.map((table) => ({
            ...table,
            schema: sourceEntity.key,
          })),
        };
      }),
    };
  }

  if (!sourceEntities) {
    error = true;
    errorDetails = 'No entities found.';
  }
  return {
    error,
    sourceEntities,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const requestDraftSource: APIRequestDraftSource = async (
  accountId,
  dataWarehouseId,
  draftSourceInput,
  abortSignal,
  force
) => {
  let error: boolean = false;
  let errorDetails: string | undefined = undefined;
  let draftSourceId: string = '';
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.mutate({
    mutation: REQUEST_DRAFT_SOURCE,
    variables: {
      command: {
        accountId,
        dataWarehouseId,
        force,
        sourceReference: draftSourceInput,
      },
    },
  });
  if (res.data.requestDraftSource && res.data.requestDraftSource.draftSourceId) {
    draftSourceId = res.data.requestDraftSource.draftSourceId;
  } else {
    error = true;
    errorDetails = 'Request draft source failed.';
  }

  return {
    draftSourceId,
    error,
    loaded: true,
    errorDetails: error && errorDetails ? errorDetails : undefined,
  };
};
const draftSource: APIDraftSource = async (draftSourceId, abortSignal) => {
  let shape = [];
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res: any = await apolloClient.query({
    query: DRAFT_SOURCE,
    variables: {
      id: draftSourceId,
    },
  });
  const draftSourcePayload = res.data.draftSource;
  if (draftSourcePayload.shape) {
    shape = draftSourcePayload.shape.columns;
  }
  return {
    status: draftSourcePayload.status,
    error: draftSourcePayload.error?.error?.message ?? draftSourcePayload.error?.message,
    sourceDescription: {
      shape,
      counter: draftSourcePayload.counter,
      sample: draftSourcePayload.sample,
    },
    loaded: true,
  };
};

const draftAudienceSource: APIDraftAudienceSource = async (
  draftSourceId,
  audienceId,
  abortSignal
) => {
  let shape = [];
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query({
    query: DRAFT_AUDIENCE_SOURCE,
    variables: {
      audienceId,
      draftSourceId,
    },
  });
  const draftSourcePayload = res.data.draftSource;
  // Get deduplicationSettings from the backend type
  const deduplicationKeys: AudienceDeduplicationKey[] = mappingDeduplicationKeysToFront(
    res.data.audience
  );
  const deployedAudience: DeployedAudience = toFrontDeployedAudienceType(res.data.deployedAudience);

  if (draftSourcePayload.shape) {
    shape = draftSourcePayload.shape.columns;
  }

  return {
    deduplicationKeys,
    deployedAudience,
    status: draftSourcePayload.status,
    error: draftSourcePayload.error?.error?.message ?? draftSourcePayload.error?.message,
    sourceDescription: {
      shape,
      counter: draftSourcePayload.counter,
      sample: draftSourcePayload.sample,
      // TODO @@@@koralex we need to align the type this function returns
      // TODO @@@@koralex missing primaryKeys, updatedAtColumnName
    },
    loaded: true,
  };
};
const draftSyncSource: APIDraftSyncSource = async (draftSourceId, syncId, abortSignal) => {
  let shape = [];
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query({
    query: DRAFT_SYNC_SOURCE,
    variables: {
      syncId,
      draftSourceId,
    },
  });
  const draftSourcePayload = res.data.draftSource;
  // Get deduplicationSettings from the backend type

  // const deployedSync: DeployedAudience = toFrontDeployedAudienceType(res.data.deployedAudience);

  if (draftSourcePayload.shape) {
    shape = draftSourcePayload.shape.columns;
  }
  return {
    status: draftSourcePayload.status,
    error: draftSourcePayload.error?.error?.message ?? draftSourcePayload.error?.message,
    syncAudienceId: res.data.deployedSync?.audienceId,
    sourceDescription: {
      shape,
      counter: draftSourcePayload.counter,
      sample: draftSourcePayload.sample,
      // TODO @@@@koralex we need to align the type this function returns
      // TODO @@@@koralex missing primaryKeys, updatedAtColumnName
    },
    loaded: true,
  };
};
const listBusinessTerms: APIListBusinessTerms = async (abortSignal) => {
  const apolloClient = await apolloClientInstance({ abortSignal });
  const res = await apolloClient.query<ListBusinessTermsOutput>({
    query: LIST_BUSINESS_TERMS,
    variables: {
      query: {},
    },
  });
  const businessTerms = res.data.businessTerms;
  return {
    businessTerms,
  };
};

export {
  getAudiences,
  getAudiencesTableList,
  getAudience,
  getAudienceData,
  saveAudience,
  updateAudience,
  deleteAudience,
  getDeployedAudiences,
  getDeployedAudience,
  getAudienceExecutions,
  getSourceEntities,
  requestPreviewAudience,
  previewAudience,
  getAudiencePreviewSample,
  requestDraftSource,
  draftSource,
  draftAudienceSource,
  draftSyncSource,
  describeSqlAudienceSourcesShapes,
  requestSqlAudienceQueryDescriptor,
  sqlAudienceQueryDescriptor,
  listBusinessTerms,
  requestAudienceExecution,
};
