export enum ConnectorType {
  bigQuery = 'bigQuery',
  googleSpreadhsheets = 'googleSpreadhsheets',
  postgres = 'postgres',
  sftp = 'sftp',
  api = 'api',
  snowflake = 'snowflake',
  salesforce = 'salesforce',
  splio = 'splio',
  mailchimp = 'mailchimp',
  braze = 'braze',
  sendinblue = 'sendinblue',
  sendinblueTracker = 'sendinblueTracker',
  brevo = 'brevo',
  shopify = 'shopify',
  selligent = 'selligent',
  mysql = 'mysql',
}
