const monitoring = {
  monitoringStatus: 'Status',
  monitoringCreatedAt: 'Created at',
  monitoringSubjectType: 'Type',
  monitoringResources: 'Resources',
  monitoringId: 'ID',
  monitoringDeploymentId: 'Deployment ID',
  monitoringExecutionId: 'Execution ID',
  monitoringCreationDate: 'Creation date',
  monitoringEndDate: 'End date',
  completionRate: 'Completion rate',
  batchSize: 'Batch size',

  deploymentDetails: 'Deployment Details',
  executionDetails: 'Execution Details',
  listOfActions: 'List of actions',

  monitoringDeployment: 'Deployment',
  monitoringExecution: 'Execution',

  monitoringCancelDeployment: 'Cancel deployment',
  monitoringCancelDeploymentConfirmation: 'Are you sure you want to cancel this deployment? All the records processing linked to this deployment will be lost',
  monitoringConfirmCancelDeployment: 'Yes, cancel deployment',

  actionResource: 'Resource',
  actionStartDate: 'Start Date',
  actionEndDate: 'End Date',
  actionStatus: 'Status',
  actionKind: 'Kind',
  actionType: 'Action',
  actionOptions: 'Options',

  monitoringStatusNothingToDo: 'Nothing To Do',
  monitoringStatusSkipped: 'Skipped',
  monitoringStatusPending: 'Pending',
  monitoringStatusInProgress: 'In Progress',
  monitoringStatusCompleted: 'Completed',
  monitoringStatusFailed: 'Failed',
  monitoringStatusCancelled: 'Cancelled',
  monitoringStatusCancelling: 'Cancelling',

  actionStatusPending: 'Pending',
  actionStatusSkipped: 'Skipped',
  actionStatusInProgress: 'In Progress',
  actionStatusFailed: 'Failed',
  actionStatusCompleted: 'Completed',
  actionStatusCancelling: 'Cancelling',
  actionStatusCancelled: 'Cancelled',

  actionSeeLogs: 'See logs',
  actionLogsTitle: 'Action logs',
  actionCopyResourceId: 'Copy {{resource}} ID',
  actionCancel: 'Cancel action',
  actionCancelConfirmation: 'Are you sure you want to cancel this action?',
  actionCancelConfirm: 'Yes, cancel action',
  
  keepRunning: 'No, keep running',

  ddlAction: 'Data Definition',
  dmlAction: 'Data Manipulation',

  // Execution actions
  sourceActionResetSourceData: 'Resource source data',
  sourceActionFetchNewSourceData: 'Fetch new source data',
  sourceActionComputeSource: 'Compute source',
  sourceActionPatchSourceData: 'Patch source data',
  sourceActionComputeSourceCheapMetrics: 'Compute source cheap metrics',
  sourceActionComputeSourceColumnExpensiveMetrics: 'Compute source expensive metrics',

  datasetActionInitializeDataset: 'Initialize dataset',
  datasetActionIntegrateSourceDataToDataset: 'Integrate source data to dataset',
  datasetActionComputeDataset: 'Compute dataset',
  datasetActionComputeDatasetCheapMetrics: 'Compute dataset cheap metrics',
  datasetActionComputeDatasetColumnExpensiveMetrics: 'Compute dataset expensive metrics',

  syncActionDeliverAllSyncRecords: 'Deliver all sync records',
  syncActionDeliverSyncRecordsIncrement: 'Deliver sync records increment',
  syncActionDeliverSpecificSyncRecords: 'Deliver specific sync records',

  analyticsSyncActionDeliverDatasetRecordsToAnalytics: 'Deliver dataset records to analytics',

  garbageCollectionActionCleanUpDataWarehouseGarbage: 'Clean up datawarehouse garbage',
  garbageCollectionActionCleanUpExecutionGarbage: 'Clean up execution garbage',
  garbageCollectionActionResetPreparedSourceData: 'Reset prepared source data',
  garbageCollectionActionCleanUpExternalDataShapeSnapshotsGarbage:
    'Clean up external data shape snapshots garbage',

  // Deployment actions
  sourceActionCreateSourceResources: 'Create source resources',
  sourceActionReInitializeSourceData: 'Reinitialize source data',
  sourceActionDeleteSourceResources: 'Delete source resources',
  sourceActionCreateRawSourceColumn: 'Create raw source column',
  sourceActionDeleteRawSourceColumn: 'Delete raw source column',
  sourceActionRenameRawSourceColumn: 'Rename raw source column',
  sourceActionCreateSourceColumn: 'Create source column',
  sourceActionDeleteSourceColumn: 'Delete source column',
  sourceActionRenameSourceColumn: 'Rename source column',
  sourceActionChangeSourceColumnType: 'Change source column type',
  sourceActionRenameSource: 'Rename source',

  relationshipActionCreateSemanticRelationshipResources: 'Create semantic relationship resources',
  relationshipActionDeleteSemanticRelationshipResources: 'Delete semantic relationship resources',
  relationshipActionCreateSourceRelationshipRecords: 'Create source relationship records',
  relationshipActionDeleteSourceRelationshipRecords: 'Delete source relationship records',

  datasetActionCreateDatasetResources: 'Create dataset resources',
  datasetActionDeleteDatasetResources: 'Delete dataset resources',
  datasetActionCreateDatasetColumn: 'Create dataset column',
  datasetActionDeleteDatasetColumn: 'Delete dataset column',
  datasetActionRenameDatasetColumn: 'Rename dataset column',
  datasetActionChangeDatasetColumnType: 'Change dataset column type',
  datasetActionChangeDatasetColumnSemanticField: 'Change dataset column semantic field',
  datasetActionEnableDatasetDeduplication: 'Enable dataset deduplication',
  datasetActionDisableDatasetDeduplication: 'Disable dataset deduplication',
  datasetActionCreateDatasetForeignKey: 'Create dataset foreign key',
  datasetActionDeleteDatasetForeignKey: 'Delete dataset foreign key',
  datasetActionCreateDatasetIndex: 'Create dataset index',
  datasetActionDeleteDatasetIndex: 'Delete dataset index',
  datasetActionUpdateDatasetIndexSources: 'Update dataset index sources',
  datasetActionPatchDatasetColumnsData: 'Patch dataset columns data',
  datasetActionReInitializeDatasetData: 'Reinitialize dataset data',
  datasetActionPatchDatasetData: 'Patch dataset data',
  datasetActionComputeDatasetForeignKeys: 'Compute dataset foreign keys',
  datasetActionComputeDatasetAggregates: 'Compute dataset aggregates',

  syncActionCreateApiSyncResources: 'Create API sync resources',
  syncActionDeleteApiSyncResources: 'Delete API sync resources',
  syncActionCreateDatabaseSyncResources: 'Create database sync resources',
  syncActionDeleteDatabaseSyncResources: 'Delete database sync resources',
  syncActionCreateFileSystemSyncResources: 'Create filesystem sync resources',
  syncActionDeleteFileSystemSyncResources: 'Delete filesystem sync resources',
  syncActionCreateWebhookSyncResources: 'Create webhook sync resources',
  syncActionDeleteWebhookSyncResources: 'Delete webhook sync resources',
  syncActionRenameSync: 'Rename sync',
  syncActionChangeSyncExistingDatasetRecordsPolicy: 'Change sync existing dataset records policy',

  sourceRelationships: 'Source Relationships',
  garbageCollection: 'Garbage Collection',
  resources: 'Resources',
};

export default monitoring;
