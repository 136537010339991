import resources from './resources';
import accountResourcesExecutionMonitoring from './account-resources-monitoring';

const common = {
  true: 'true',
  false: 'false',
  apply: 'Apply',
};

const sourceCatalogScreen = {
  sourceCatalog: 'Sources',
  catalog: 'Catalog',

  'thousand-separator': 'Thousand separator',
  'decimal-separator': 'Decimal separator',
  'true-value': 'True value',
  'false-value': 'False value',
  timezone: 'Timezone',
  format: 'Format',
  'empty-date-custom-format': 'Write custom format!',
  'write-date-custom-format': 'Write custom format',
  'select-date-format': 'Please select a format!',
  'select-timezone': 'Please select a timezone!',
  unit: 'Unit',
  seconds: 'Seconds',
  milliseconds: 'Milliseconds',
  'rounding-operation': 'Rounding operation',
  round: 'round',
  floor: 'floor',
  ceil: 'ceil',
};

const monitoringScreen = {
  logDetails: 'Log Details',
  loading: 'Loading ...',
  filters: 'Filters',
  objectType: 'Object type',
  date: 'Date',
  created: 'Created',
  started: 'Started',
  ended: 'Ended',
  timing: 'Timing',
  status: 'Status',
  executionTime: 'Execution time',
  trigger: 'Trigger',
  executionId: 'Execution Id',
  at: 'at',
};
const sourcesScreen = {
  sourcesDescription: 'Sources are places outside of the datahub from which you get data.',
  addASource: 'Add a source',
  // source keys
  name: 'Name',
  connector: 'Connector',
  pointer: 'Pointer',
  records: 'Records',
  lastRun: 'Last run',
  status: 'Status',
  schedule: 'Schedule',
  actions: 'Actions',
  // source actions
  create: 'Create',
  view: 'View',
  edit: 'Edit',
  open: 'Open',
  log: 'Log',
  active: 'Active',
  broken: 'Broken',
  // form
  editTheSource: 'Edit the source',
  viewTheSource: 'View the source',
  createTheSource: 'Create the source',
  chooseConnector: 'Choose connector',
  configuration: 'Configuration',
  sourcePointer: 'Source pointer',
  globalSettings: 'Global settings',
  usageInFlows: 'Usage in flows',
  sourceUsedInFlows: 'This source is currently being used in the following flows',
  connectorRequiredMessage: 'Please select a connector.',
  pointerRequiredMessage: 'Please select a pointer.',
  nameRequiredMessage: 'Please select a name.',
  statusRequiredMessage: 'Please select a status.',
  creationFlowRequiredMessage: 'Please choose if you want to create a flow for this source.',
  creatFlowLabel: "Create a flow to fuel an object with this source's data",
  confirmDeletion: 'Are you sure? The source will be deleted.',
  confirmCancel: 'Are you sure? Your changes will not be saved.',
  save: 'Save',
  next: 'Next',
  choose: 'Choose',
  previous: 'Previous',
  cancel: 'Cancel',
  delete: 'Delete',
  yes: 'Yes',
  no: 'No',
  reset: 'Reset',
  confirmReset: 'Do you really want to reset this source? All existing data will be wiped out.',
};
const menuHeader = {
  controlCenter: 'Control center',
  settings: 'Settings',
};
const menuLeft = {
  connections: 'Connections',
  sources: 'Sources',
  allSources: 'All sources',
  flows: 'Flows',
  allFlows: 'All flows',
  sourceFlows: 'Source flows',
  destinationsFlows: 'Destination flows',
  internalFlows: 'Internal flows',
  recipes: 'Recipes',
  objects: 'Objects',
  allObjects: 'All Objects',
  contacts: 'Contacts',
  orders: 'Orders',
  products: 'Products',
  destinations: 'Destinations',
  allDestinations: 'All destinations',
  syncs: 'Syncs',
  dataset: 'Dataset',
  datasets: 'Datasets',
  monitoring: 'Monitoring',
  settings: 'Settings',
};

const datasetsScreen = {
  mappedSourcesCountTooltip:
    'Number of mapped sources / Number of sources with the same semantic entity',
  mappedSourceColumnsCountTooltip: 'Number of mapped columns / Number of all columns',
};

const syncsScreen = {
  a: { b: 'hello' },
  // screens
  syncsDescription: 'Syncs map sources to a Destination',
  addNewSync: 'Add sync',
  createANewSync: 'Create a new Sync',
  // form
  whatDatasetDoSync: 'What Dataset do you want to sync?',
  existingDatasetRecordsPolicy: 'What should the sync do with existing dataset records?',
  ignoreExistingDatasetRecordsPolicy: 'Ignore them',
  includeExistingDatasetRecordsPolicy: 'Include them',
  selectDataset: 'Select a Dataset',
  newDataset: 'New Dataset',
  sendARawSource: 'Send a raw source',
  sendASegment: 'Send a raw segment',
  createANewSource: 'Create a new source ',
  createANewSegment: 'Create a new segment',
  addSource: 'Add Source',

  addDestination: 'Add Destination',
  originTypeLabel: 'What data do you want to sync?',
  chooseYourSource: 'Choose your source',
  chooseYourSegment: 'Choose your segment',
  chooseYourOrigin: 'Choose your origin',
  source: 'Source',
  segment: 'Segment',
  origin: 'Origin',
  settings: 'Settings',
  destination: 'Destination',
  whereSyncDataQuestion: 'Where do you want to sync the Dataset to?',
  whichFieldToSyncQuestion: 'Which fields should be synced?',
  whenDoYouWantToSync: 'When do you want to sync the Dataset?',
  nameAndActivateThisSync: 'Name and activate this sync ',
  weWillCastText: 'We will cast ',
  stringAsText: " to string as it's currently stored as ",
  weCannotSendText: 'We cannot send ',
  asAText: ' as a ',
  updateMappingText: ', please update mapping.',
  sourceColumnsTitle: 'Source columns (CDP)',
  datasetColumnTitle: 'Dataset column',
  destinationFieldTitle: 'Destination field',
  chooseDestination: 'Choose destination',
  chooseDataset: 'Choose Dataset',
  refreshFieldsTitle: 'Refresh fields',
  addMappingText: 'Add Mapping',
  addStaticValueText: 'Add Static Value',
  syncNameLabel: 'Sync name',
  writeNameHere: 'Write name here',
  syncNameInputRequiredMessage: 'Please enter a valid name',
  activeLabel: 'Make it active now',
  viewTheSync: 'View the sync',
  editTheSync: 'Edit the sync',
  originSettings: 'Origin Settings',
  destinationSettings: 'Destination Settings',
  mappingSettings: 'Mapping Settings',
  originStepFailed: 'origin step failed and not authorized to get to this screen',
  mappingStaticValidValue: 'Please enter a valid static value !',
  mappingOriginEmptyValue: 'Please enter an origin column !',
  mappingTargetEmptyValue: 'Please enter the destination field !',
  mappingInvalidAlert: 'Please input a valid ',
  whatKindOfSync: 'What kind of sync would you like us to perform?',
  chooseAction: 'Choose action',
  whatShouldWedoRecordLeaves: 'What should we do when a record leaves the Dataset?',
  syncTypeRequiredRuleMessage: 'Please choose the sync type',
  recordsActionTypeRequiredRuleMessage: 'Please choose records action',
  afterCreation: 'Sync only when a record is created in the Dataset',
  upsert: 'Sync when a record is created or updated (upsert) in Dataset',
  syncSegmentLeaveignore: 'Do nothing',
  syncSegmentLeavedelete: 'Delete it in the destination',

  confirmSyncDeletion: 'Are you sure? The Sync will be deleted.',
  mappingTargetAlreadyUsed:
    'This destination field name is already in use. Please choose a different one.',
  mappingTargetAlreadyExists:
    'This destination field name already exists in the options. Please select a different one or use an existing option.',
};
const segmentScreen = {
  createANewSegment: 'Create a new segment',
  editSegment: 'Edit segment',
  segmenCombineMultiple: 'Segments used to combine multiple sources',
  selectSourceOrCreateOne: 'Select a source or create one',
  saveAndSync: 'Save & Sync',
  whatDataToSegment: 'What data do you want to work with?',
  chooseYourBaseData: 'Choose your base data',
  addData: 'Add data',
  updateCombinedSource: 'Update combined source',
  resetSources: 'Reset sources',
  resetSource: 'Reset source',
  selectSourceOrCreate: 'Select a source or create one',
  segmentNameRequiredMessage: 'Please enter the segment name',
  segmentName: 'Enter the segment name',
  howToSegmentData: 'How do you want to work with your data?',
  easy: 'Easy mode',
  sql: 'Use SQL',
  filterData: 'Filter the data',
  //form
  combineSources: 'Combine Sources',
  explore: 'Explore',
  combinationSettings: 'Combination settings',
  columnSelection: 'Column selection',
  whatSourceLikeToCombine: 'What source would you like to combine with ',
  connection: 'Connection',
  file: 'File',
  recordsInTotal: 'records in total',
  recordsInSample: 'records in sample',
  howShouldCombineNewSource: 'How should we combine this new source with the other one?',
  ofRecordsFrom: ' of records from ',
  matchOnesFrom: ' match ones from ',
  whatRecordsShouldWeKeep: 'What records should we keep for this new source?',
  severalRecordsOfCombinedSource: ' Several records of combined source match each record of ',
  requiredCombinationTypeMessage: 'Please choose combination type',
  whatShouldWeDo: ', what should we do?',
  chooseColumn: 'Choose Column',
  chooseFormula: 'Choose formula',
  pleaseChooseTheFormula: 'Please choose the formula!',
  requiredColumnRuleMessage: 'Please choose the column',
  clickToReduce: 'Click to reduce stats',
  clickToExpand: 'Click to expand stats',
  statistics: 'Statistics',
  sampleValues: 'Sample values',
  //formula
  sum: 'Sum',
  max: 'Max',
  min: 'Min',
  avg: 'Avg',
  first: 'First',
  last: 'Last',
  // segments table
  sourceName: 'Source',
  combinedSources: 'Combined sources',
};
const recipeScreen = {
  pleaseEnterRecipeName: 'Please enter a name for the recipe!',
  availableDataOrigins: 'Available data origins',
  availableDataTargets: 'Available data targets',
  useModel: 'Use model',
  context: 'Context',
  testQuery: 'Test query',
  queryRunSuccess: 'Query run with Success',
  queryFailed: 'Query failed',
  runQuery: 'Run query',
  compiledSql: 'Compiled SQL',
  results: 'results',
  logs: 'Logs',
  pleaseExecuteQuery: 'Please execute query',
  youCanSaveRecipe: 'You can save your recipe now',
  queryMustBeTested: 'Query must be tested with success',
};
const audiencesScreen = {
  newDataset: 'New Dataset',
  confirmDatasetDeletion: 'Are you sure? The Dataset will be deleted.',
};
const scheduleContainer = {
  trigger: 'Trigger',
  realTime: 'Real time',
  regular: 'Regular interval',
  manual: 'Manual',
  triggerRequiredRuleMessage: 'Trigger is required!',
  scheduleEvery: 'Schedule every',
  scheduleRequiredRuleMessage: 'Number is required!',
  intervalRequiredRuleMessage: 'Interval is required!',
  startingDateRequiredRuleMessage: `Starting date required!`,
  startingDateValidator: `Invalid CRON expression`,
  startingAtLabel: 'At',
  startingFromLabel: 'Starting from',
  hour: 'Hour',
  day: 'Day',
  month: 'Month',
  cronExpressionLabel: 'Cron expression',
  cronExpressionRequiredRuleMessage: 'Please type the cron expression!',
  whatColumnsFromNewSource: 'What columns from this new source should we add?',
  pleaseCheckTheColumns:
    'Please check the columns you want to add, and choose the formula we should apply to each one of them.',
};
const connectionsScreen = {
  connectionsListDescription:
    'Add Sources and Destinations to Octolis to get started and do more with your data.',
  addMoreLabel: '+ Add more',
  connectionsDeleteErrors: {
    connectionUsedByResource:
      'This connection is used by a source or a sync. It cannot be deleted.',
    unexpectedError:
      'Something went wrong. Please try again. If the problem persists, please contact support.',
  },
};

const settingsScreen = {
  'settings-account': 'Account',
  'settings-profile': 'Profile',
  'settings-plan': 'Plan',
  'settings-sync-to-analytics': 'Sync to Analytics',
  logout: 'Logout',

  'settings-password': 'Password',

  'settings-account-description': 'Description',
  'settings-account-database': 'Database',
  'settings-account-name': 'Account name',
  'settings-account-database-description':
    'Octolis works on top of a database (Postgres). Here is your database credentials (read replica) if you want to plug a BI tool for analytics.',
  'settings-account-db-host': 'Host',
  'settings-account-db-user': 'User',
  'settings-account-db-database': 'Database',
  'settings-account-db-port': 'Port',
  'settings-account-db-ssl': 'SSL',
  'settings-account-db-ssl-true': 'YES',
  'settings-account-db-ssl-false': 'NO',

  'settings-profile-myProfile': 'My profile',
  'settings-profile-role': 'Role',
  'settings-profile-login': 'Login',
  'settings-profile-reset-password': 'Reset password',
  'settings-profile-reset-password-succeeded-title': 'Password Reset Success',
  'settings-profile-reset-password-succeeded':
    'We have received your password reset request. Please check your email for further instructions.',
  'settings-profile-reset-password-failed-title': 'Password Reset Request Failed',
  'settings-profile-reset-password-failed':
    'We apologize, but there was an issue processing your password reset request. Please try again later or contact support for assistance.',

  'settings-plan-subscription': 'Subscription',
  'settings-plan-essential': 'Essential',
  'settings-plan-professional': 'Professional',
  'settings-plan-custom': 'Custom',
};

const dataModelScreen = {
  dataModel: 'Data models',

  createEntityFormTitle: 'Create a custom entity',
  createEntityFormNameTitle: 'Name',
  createEntityFormIdTitle: 'Id',
  createEntityFormNamePlaceholder: 'Write name here',
  createEntityFormIdPlaceholder: 'Write id here',
  createEntityFormNameRequiredMessage: 'Please enter a name!',
  createEntityFormIdRequiredMessage: 'Please enter an id!',
  createEntityFormRelationshipsTitle: 'Relationships',
  deleteEntity: 'Delete',
  deleteEntityConfirmation: 'Are you sure you want to delete this entity?',
  deleteEntityConfirmationDescription:
    'This action is irreversible and will delete all data related to this entity.',
};

const en = {
  translation: {
    ...common,
    ...menuLeft,
    ...menuHeader,
    ...sourcesScreen,
    ...syncsScreen,
    ...scheduleContainer,
    ...connectionsScreen,
    ...segmentScreen,
    ...recipeScreen,
    ...audiencesScreen,
    ...monitoringScreen,
    ...sourceCatalogScreen,
    ...settingsScreen,
    ...datasetsScreen,
    ...resources,
    ...accountResourcesExecutionMonitoring,
    ...dataModelScreen,
  },
};
export default en;
