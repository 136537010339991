import {
  Connector,
  ConnectorCategoriesEnum,
  ConnectorExternalType,
} from '@features/connectors/types';
import { ConnectorType } from '@features/connections/types';

export const connectors: Connector[] = [
  // {
  //   key: 'connector1',
  //   title: 'Salesforce',
  //   connectorType: ConnectorType.api,
  //   description: 'This is some description of the source connector',
  //   category: ConnectorCategoriesEnum.API,
  //   logo: '/icons/logos/salesforce.png',
  //   expandedLogo: '/icons/salesforce.png',

  //   canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  // },
  {
    key: 'connector2',
    title: 'Brevo',
    connectorType: ConnectorType.sendinblue,
    description: 'This is some description of the source connector',
    category: ConnectorCategoriesEnum.API,
    logo: 'https://cdn.octolis.cloud/partners/brevo-icon.svg',
    expandedLogo: 'https://cdn.octolis.cloud/partners/brevo-icon.svg',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  {
    key: 'connector3',
    title: 'PostgreSQL',
    connectorType: ConnectorType.postgres,
    description: 'This is some description of the source connector',
    category: ConnectorCategoriesEnum.DB,
    logo: '/icons/logos/postgresql.png',
    expandedLogo: '/icons/postgres.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  {
    key: 'connector4',
    title: 'FTP',
    connectorType: ConnectorType.sftp,
    description: 'This is some description of the source connector',
    category: ConnectorCategoriesEnum.FS,
    logo: '/icons/ftp.png',
    expandedLogo: '/icons/ftp.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  // {
  //   key: 'connector7',
  //   title: 'Intercom',
  //   connectorType: ConnectorType.api,
  //   description: 'This is some description of the destination connector',
  //   category: ConnectorCategoriesEnum.API,
  //   logo: '/icons/logos/intercom.png',
  //   expandedLogo: '/icons/intercom.png',
  //   canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  // },
  // {
  //   key: 'connector8',
  //   title: 'Zendesk',
  //   connectorType: ConnectorType.api,
  //   description: 'This is some description of the destination connector',
  //   category: ConnectorCategoriesEnum.API,
  //   logo: '/icons/logos/zendesk.png',
  //   expandedLogo: '/icons/Zendesk-expanded.png',
  //   canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  // },
  {
    key: 'connector9',
    title: 'Splio',
    connectorType: ConnectorType.splio,
    description: 'This is some description of the destination connector',
    category: ConnectorCategoriesEnum.API,
    logo: '/icons/logos/splio.png',
    expandedLogo: '/icons/splio-expanded.png',
    canBe: [ConnectorExternalType.source, ConnectorExternalType.destination],
  },
  // {
  //   key: 'connector10',
  //   title: 'Google Sheets',
  //   connectorType: ConnectorType.googleSpreadhsheets,
  //   description: 'This is some description of the destination connector',
  //   category: ConnectorCategoriesEnum.API,
  //   logo: '/icons/logos/gads.png',
  //   expandedLogo: '/icons/gads.png',
  //   canBe: [ConnectorExternalType.source],
  // },
  {
    key: 'connector14',
    title: 'BigQuery',
    connectorType: ConnectorType.bigQuery,
    description: 'This is some description of the destination connector',
    category: ConnectorCategoriesEnum.DB,
    logo: '/icons/logos/bigquery.png',
    expandedLogo: '/icons/logos/bigquery.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  {
    key: 'connector15',
    title: 'Shopify',
    connectorType: ConnectorType.shopify,
    description: 'This is some description of the destination connector',
    category: ConnectorCategoriesEnum.API,
    logo: '/icons/logos/shopify.png',
    expandedLogo: '/icons/logos/shopify.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  {
    key: 'connector16',
    title: 'Braze',
    connectorType: ConnectorType.braze,
    description: 'This is some description of the destination connector',
    category: ConnectorCategoriesEnum.API,
    logo: '/icons/logos/braze.png',
    expandedLogo: '/icons/logos/braze.png',
    canBe: [ConnectorExternalType.destination],
  },
  {
    key: 'connector17',
    title: 'Mailchimp',
    connectorType: ConnectorType.mailchimp,
    description: 'This is some description of the destination connector',
    category: ConnectorCategoriesEnum.API,
    logo: '/icons/logos/mailchimp.png',
    expandedLogo: '/icons/logos/mailchimp.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  {
    key: 'connector18',
    title: 'Brevo Tracker',
    connectorType: ConnectorType.sendinblueTracker,
    description: 'This is some description of the source connector',
    category: ConnectorCategoriesEnum.API,
    logo: 'https://cdn.octolis.cloud/partners/brevo-icon.svg',
    expandedLogo: 'https://cdn.octolis.cloud/partners/brevo-icon.svg',
    canBe: [ConnectorExternalType.destination],
  },
  {
    key: 'connector19',
    title: 'Selligent',
    connectorType: ConnectorType.selligent,
    description: 'This is some description of the source connector',
    category: ConnectorCategoriesEnum.API,
    logo: '/icons/logos/selligent.png',
    expandedLogo: '/icons/selligent.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  {
    key: 'connector20',
    title: 'Brevo',
    connectorType: ConnectorType.brevo,
    description: 'This is some description of the source connector',
    category: ConnectorCategoriesEnum.API,
    logo: '/icons/logos/brevo.png',
    expandedLogo: '/icons/brevo.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
  {
    key: 'connector21',
    title: 'MySQL',
    connectorType: ConnectorType.mysql,
    description: 'This is some description of the destination connector',
    category: ConnectorCategoriesEnum.DB,
    logo: '/icons/logos/mysql.png',
    expandedLogo: '/icons/mysql.png',
    canBe: [ConnectorExternalType.destination, ConnectorExternalType.source],
  },
];

export const connectorsCategoriesData: {
  key: ConnectorCategoriesEnum;
  name: string;
}[] = [
  {
    key: ConnectorCategoriesEnum.API,
    name: 'Marketing software APIs',
  },
  {
    key: ConnectorCategoriesEnum.DB,
    name: 'Datawarehouses',
  },
  {
    key: ConnectorCategoriesEnum.FS,
    name: 'Static files storage',
  },
];
